<!--
 * @Author       : JiangChao
 * @Date         : 2022-02-19 11:51:03
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-04 08:56:23
 * @Description  : 
-->
<template>
    <div class="container" :style="{ minHeight: boxheight }">
        <qrcode-stream class="camera" @decode="onDecode"></qrcode-stream>

        <img
            class="img-style"
            :src="require('@/assets/imgs/png/qr_bg.png')"
           
        />
        <div class="tip">將QR Code放入掃瞄框內即可租借</div>

        <div
            class="header container-pa16 flex-container space-between width-fill"
        >
            <img
                class="width-36"
                :src="require('@/assets/imgs/svg/cancel.svg')"
                @click="$_back()"
            />
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        show: true,
    }),
    components: {},
    methods: {
        $_back() {
            history.go(-1);
        },
        onDecode(decodedString) {
            console.log("decodedString: ", decodedString);
            if (decodedString) {
                // this.$emit("decode", decodedString);
                this.$router.push({
                    name: "map",
                    params: {
                        id: decodedString,
                    },
                });
            }
        },
    },
     computed: {
            boxheight() {
                return `${window.innerHeight}px`;
            },
        },
};
</script>

<style scoped lang="less">
.container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

}

.block {
    width: 120px;
    height: 120px;
    background-color: #fff;
}
.camera {
    border: 2px solid lightblue;
    position: absolute;
}

.img-style {
    position: absolute;
    // top: 50px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    //  width: auto;  
    height: auto;  
    max-width: 100%;  
    max-height: 100%;  


 
}
.tip {
    color: white;
    position: absolute;
    bottom: 0;
    margin-bottom: 210px;
}
</style>
